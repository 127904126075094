import React from 'react';
import { Link } from 'gatsby';

import { useSelector, useDispatch } from 'react-redux';
import { close, startClosing } from '../store/hoverableQuestions';

import CloseIcon from './../assets/icons/close.svg';
import DocumentIcon from './../assets/icons/pdf2.svg';
import WordDocumentIcon from './../assets/icons/word-icon.png';

const LS_CLOSED_HOVERABLES = `closedHoverables-${new Date().toDateString()}`;

function LinkIcon({ size = '1rem' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      style={{
        width: size,
        height: size,
      }}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
      />
    </svg>
  );
}

function Chevron({ size = '1rem' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      style={{
        width: size,
        height: size,
      }}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
    </svg>
  );
}

export default function () {
  const questions = useSelector((state) => state.hoverableQuestions.questions);

  const dispatch = useDispatch();

  const closeQuestion = (i) => {
    const alreadyClosed = JSON.parse(localStorage.getItem(LS_CLOSED_HOVERABLES) ?? '[]');
    alreadyClosed.push(questions[i].node.originalId);
    localStorage.setItem(LS_CLOSED_HOVERABLES, JSON.stringify(alreadyClosed));
    dispatch(startClosing(i));
    setTimeout(() => dispatch(close(i)), 500);
  };

  return (
    <div className="questions-container">
      {questions?.map(({ node: question }, i) => {
        return !question.closed && !question.hideFromSite ? (
          <div
            key={i}
            className={`question-container ${question.forceOpen ? 'force-open' : ''} ${
              question.closing ? 'closed' : 'open'
            }`}
          >
            <div className="close-icon" onClick={() => closeQuestion(i)}>
              <img src={CloseIcon} alt="Close icon" />
            </div>
            <div className="chevron">
              <Chevron />
            </div>
            <p>{question.title}</p>
            <p className="description">{question.shortDescription}</p>
            <div className="button-row">
              {(question.file || question.fileUrl) && (
                <a
                  className="mb-0.675 hoverable-link"
                  href={question.fileUrl || question.file.url}
                  target="_blank"
                >
                  <button className="button hoverable-button">
                    <img
                      src={
                        question.file
                          ? question.file.url.includes('.docx')
                            ? WordDocumentIcon
                            : DocumentIcon
                          : question.fileUrl.includes('.docx')
                          ? WordDocumentIcon
                          : DocumentIcon
                      }
                      className="hoverable-icon"
                      alt="Document icon"
                    />
                    {question.callToActionText ? question.callToActionText : 'Get file'}
                  </button>
                </a>
              )}
              {question.link && (
                <Link className="hoverable-link" to={question.link}>
                  <button className="button hoverable-button">
                    <LinkIcon />
                    {question.callToActionText ? question.callToActionText : 'Visit link'}
                  </button>
                </Link>
              )}
            </div>
          </div>
        ) : null;
      })}
    </div>
  );
}
